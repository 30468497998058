<template>
  <div class="flex-grow-1 d-flex align-items-center justify-content-center">
        <div class="login-container">
          <h5 class="text-gray-100 text-center mb-3">Login</h5>
          <b-form class="mb-2 invalid" @submit.prevent="login()">
            <tp-icon-input icon="person" :state="valid" v-model="username" required class="mb-3" type="email" placeholder="Email" />
            <tp-icon-input icon="key" :state="valid" v-model="password" required class="mb-3" type="password" placeholder="Password" autocomplete="true" />


            <b-btn :disabled="loading" class="w-100 rounded-pill button-gradient-primary border-0" type="submit">
              <span v-if="loading">
                <b-spinner style="height: 16px; width: 16px;"  label="Spinning" />
                Loading
              </span>

              <span v-if="!loading">Login</span>
            </b-btn>
          </b-form>

          <div class="d-flex">
            <router-link to="/sign-up" class="small link-gray-200 ml-1">
              Sign up
            </router-link>

            <router-link to="/recovery_password" class="small link-gray-200 ml-auto">
              Forgot password?
            </router-link>
          </div>
        </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    username: '',
    password: '',
    valid: null,
    loading: false,
  }),

  methods: {
    login() {
      this.loading = true
      this.$store.dispatch('LOGIN', {
        username: this.username,
        password: this.password,
      })
          .then(() => {
            this.valid = true
            this.loading = false
          })
          .catch(error => {
            this.loading = false

            if(error.response.status === 400) {
              this.$bvToast.toast('Wrong login or password', {
                variant: 'danger',
                title: 'Authorization error',
                autoHideDelay: 5000
              })

              this.valid = false
            } else  if(error.response.status === 400) {
              this.$bvToast.toast('Server side error', {
                variant: 'danger',
                title: 'Server error',
                autoHideDelay: 5000
              })
            }
          })
    }
  }
}
</script>

<style scoped>
.login-container {
  margin-bottom: 80px;
}
</style>